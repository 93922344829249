


































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { Row, useDate, useLocalFilter } from "@/hooks";
import {
  DATE_TIMESTAMP,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseBranch,
  DataWarehouseLocation,
  ReceivingItemListResponseDTO,
  StatusProduct,
} from "@/models/interface/logistic.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { ListContactDataDto } from "@interface/contact-data";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment, { Moment } from "moment";
import printJs from "print-js";
import { Component, Vue } from "vue-property-decorator";

type FormValue = {
  branch: string;
  poNumber: string;
  rack: string;
  receiveDateFrom: Moment;
  receiveDateTo: Moment;
  receivingNumber: string;
  status: string;
  supplierName: string;
  warehouse: string;
};

@Component
export default class ListReceivingItems extends Vue {
  useLocalFilter = useLocalFilter;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  dataBranch: DataWarehouseBranch[] = [];
  loadingdatalistLocationRack = false;
  datalistLocationRack = [] as any[];
  datalistSupplierName: Array<ListContactDataDto> = [];
  loadingdatalistsupplierName = false;
  loadingdatalistbranch = false;
  datalistStatus: StatusProduct[] = [];
  datalistLocation: DataWarehouseLocation[] = [];
  dataListItems: Array<Row<ReceivingItemListResponseDTO, string>> = [];
  loadingdatalistLocation = false;
  loadingDownload = false;
  loadingPrint = false;
  totalData = 0;
  page = 0;
  limit = 10;
  form!: WrappedFormUtils;
  loading = {
    find: false,
    branch: false,
  };
  formRules = {
    supplierName: {
      label: "lbl_supplier",
      name: "supplierName",
      placeholder: "lbl_supplier",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    poNumber: {
      label: "lbl_po_number",
      name: "poNumber",
      placeholder: "lbl_po_number",
      decorator: [
        "poNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    warehouse: {
      label: "lbl_warehouse",
      name: "warehouse",
      placeholder: "lbl_warehouse",
      decorator: [
        "warehouse",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rack: {
      label: "lbl_rack",
      name: "rack",
      placeholder: "lbl_rack",
      decorator: [
        "rack",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    receiveDateFrom: {
      label: "lbl_date_from",
      name: "receiveDateFrom",
      placeholder: "lbl_date_from",
      decorator: [
        "receiveDateFrom",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    receiveDateTo: {
      label: "lbl_date_to",
      name: "receiveDateTo",
      placeholder: "lbl_date_to",
      decorator: [
        "receiveDateTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    receivingNumber: {
      label: "lbl_document_number",
      name: "receivingNumber",
      placeholder: "lbl_document_number",
      decorator: [
        "receivingNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status",
      decorator: ["status"],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "receiveNumber",
      key: "receivingNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_number"),
      dataIndex: "purchaseOrderNumber",
      key: "poNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "receiveDate",
      key: "receivingDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNumber",
      scopedSlots: { customRender: "journal" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "locationReceived",
      key: "location",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      key: "uom",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      scopedSlots: { customRender: "operation" },
      align: "center",
    },
  ];

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "receivingitems" });
  }

  created() {
    this.getBranch("");
    this.getListContact("");
    this.getListOfStatus();
    this.getListOfWarehouseLocation("");
    this.getListOfWarehouseLocationRack("");
  }

  handleBranch() {
    this.form.resetFields(["warehouse", "rack"]);
    this.getListOfWarehouseLocation("");
  }
  handleLocation() {
    this.form.resetFields(["rack"]);
    this.getListOfWarehouseLocationRack("");
  }

  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    return logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  // responseEditTable(response) {
  //   this.$router.push(
  //     `/purchasing/receivingitems/goodreceipt/edit/${response.receivingId}`
  //   );
  // }

  clearItems() {
    this.form.resetFields();
  }
  getListOfWarehouseLocationRack(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value && this.form.getFieldValue("warehouse")) {
      params.search = `name~*${value}*_AND_warehouse.secureId~${this.form.getFieldValue(
        "warehouse"
      )}`;
    } else if (!value && this.form.getFieldValue("warehouse")) {
      params.search = `warehouse.secureId~${this.form.getFieldValue(
        "warehouse"
      )}`;
    } else if (value && !this.form.getFieldValue("warehouse")) {
      params.search = `name~*${value}*`;
    }
    this.loadingdatalistLocationRack = true;
    logisticServices
      .listWarehouseLocation(params, "")
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.datalistLocationRack = res.data;
      })
      .finally(() => (this.loadingdatalistLocationRack = false));
  }

  buildSearch(value: FormValue): string {
    const { toEndDay, toStartDay } = useDate();
    const builder = new SearchBuilder();
    const search: Array<string> = [];

    if (value.supplierName) {
      search.push(
        builder
          .push(["receivingItem.supplier.firstName", value.supplierName], {
            like: "both",
          })
          .or()
          .push(["receivingItem.supplier.lastName", value.supplierName], {
            like: "both",
          })
          .build()
      );
      builder.destroy();
    }

    if (value.branch) {
      search.push(
        builder.push(["receivingItem.branch.secureId", value.branch]).build()
      );
      builder.destroy();
    }

    if (value.poNumber) {
      search.push(
        builder
          .push(
            ["receivingItem.purchaseOrder.documentNumber", value.poNumber],
            { like: "both" }
          )
          .build()
      );
      builder.destroy();
    }

    if (value.receivingNumber) {
      search.push(
        builder
          .push(["receivingItem.receiveNumber", value.receivingNumber], {
            like: "both",
          })
          .build()
      );
      builder.destroy();
    }

    if (value.warehouse) {
      search.push(
        builder
          .push(["locationReceived.warehouse.secureId", value.warehouse])
          .build()
      );
      builder.destroy();
    }

    if (value.rack) {
      search.push(
        builder.push(["locationReceived.secureId", value.rack]).build()
      );
      builder.destroy();
    }

    if (value.receiveDateFrom) {
      search.push(
        builder
          .push(
            [
              "receivingItem.receiveDate",
              toStartDay(value.receiveDateFrom).format(),
            ],
            { het: true }
          )
          .build()
      );
      builder.destroy();
    }

    if (value.receiveDateTo) {
      search.push(
        builder
          .push(
            [
              "receivingItem.receiveDate",
              toEndDay(value.receiveDateTo).format(),
            ],
            { let: true }
          )
          .build()
      );
      builder.destroy();
    }

    if (value.status) {
      search.push(builder.push(["receivingItem.status", value.status]).build());
      builder.destroy();
    }

    return search.join(builder.AND);
  }

  handleFind(page = false): void {
    this.form.validateFields((err, values: FormValue) => {
      if (err) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }
      const params: RequestQueryParamsModel = {
        limit: this.limit,
        page: page ? this.page - 1 : 0,
        sorts: "createdDate:desc",
        search: this.buildSearch(values) ?? null,
      };
      this.loading.find = true;
      logisticServices
        .listOfReceivingItems(params)
        .then(res => {
          this.dataListItems = res.data.map(item => ({
            key: item.id,
            ...item,
          }));
          this.totalData = res.totalElements;
        })
        .finally(() => (this.loading.find = false));
    });
  }

  download(): void {
    const params: RequestQueryParamsModel = {
      fromChecklist: false,
    };
    this.form.validateFields((_err, values) => {
      params.search = this.buildSearch(values) ?? null;
      params.params = this.checkParams(values);
      this.loadingDownload = true;
      const prefixFilename = moment().format(DATE_TIMESTAMP);
      logisticServices
        .downloadReportReceivingItems(params)
        .then(dataBlob => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${prefixFilename}-Report of Good Receipt with Price.xlsx`
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loadingDownload = false));
    });
  }

  print(): void {
    const params: RequestQueryParamsModel = {
      fromChecklist: false,
    };
    this.form.validateFields((_err, values) => {
      params.search = this.buildSearch(values) ?? null;
      params.params = this.checkParams(values);
      this.loadingPrint = true;
      logisticServices
        .printReportReceivingItems(params)
        .then(dataBlob => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            printJs({
              printable: url,
              type: "pdf",
              targetStyles: "*",
              showModal: true,
              modalMessage: "Loading ...",
            });
          }
        })
        .finally(() => (this.loadingPrint = false));
    });
  }

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }
  checkParams(res: FormValue): string {
    let params = "";
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(`,${res.supplierName}`);
    params += this.checkValue(`,${res.poNumber}`);
    params += this.checkValue(`,${res.receivingNumber}`);
    params += this.checkValue(
      `,${this.dataBranch.find(item => item.id === res.branch)?.name}`
    );
    if (res.warehouse) {
      res.warehouse =
        this.datalistLocation
          .find(item => item.id == res.warehouse)
          ?.name.replaceAll(",", " ") || "";
    }
    if (res.rack) {
      res.rack = this.datalistLocationRack
        .find(item => item.id == res.rack)
        ?.name.replaceAll(",", " ");
    }
    params += this.checkValue(`,${res.warehouse}`);
    params += this.checkValue(`,${res.rack}`);
    params += this.checkValue(`,${res.status}`);
    params += this.checkValue(
      res.receiveDateFrom ? `,${res.receiveDateFrom.format("DD-MMM-yyyy")}` : ""
    );
    params += this.checkValue(
      res.receiveDateTo ? `,${res.receiveDateTo.format("DD-MMM-yyyy")}` : ""
    );

    return params;
  }

  // createNew(): void {
  //   this.$router.push("/purchasing/receivingitems/goodreceipt/create");
  // }

  getListOfWarehouseLocation(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value && this.form.getFieldValue("branch")) {
      params.search = `name~*${value}*_AND_branchWarehouse.secureId~${this.form.getFieldValue(
        "branch"
      )}`;
    } else if (!value && this.form.getFieldValue("branch")) {
      params.search = `branchWarehouse.secureId~${this.form.getFieldValue(
        "branch"
      )}`;
    } else if (value && !this.form.getFieldValue("branch")) {
      params.search = `name~*${value}*`;
    }
    this.loadingdatalistLocation = true;
    logisticServices
      .listWarehouse(params, "")
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.datalistLocation = res.data;
      })
      .finally(() => (this.loadingdatalistLocation = false));
  }
  getListOfStatus() {
    let params = {
      name: "RECEIVE_ITEM_STATUS",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then(res => {
      this.datalistStatus = res;
    });
  }
  getListContact(value) {
    let params = {
      limit: 10,
      page: 0,
      search: `supplier~true_AND_active~true`,
    } as RequestQueryParamsModel;
    if (value) {
      params.search = `supplier~true_AND_active~true_AND_firstName~*${value}*`;
    } else {
      params.search = `supplier~true_AND_active~true`;
    }
    this.loadingdatalistsupplierName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.datalistSupplierName = res.data;
      })
      .finally(() => (this.loadingdatalistsupplierName = false));
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.page = pagination.current;
    if (this.limit !== pagination.pageSize) {
      this.page = 1;
    }

    this.limit = pagination.pageSize;
    this.handleFind(true);
  }

  allowEdit(status: LogisticReceivingItemStateEnum): boolean {
    const editStatus: Array<LogisticReceivingItemStateEnum> = [
      LogisticReceivingItemStateEnum.DRAFT,
    ];
    return editStatus.includes(status);
  }
}
